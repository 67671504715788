import React, {FC} from "react";
import {useSelector} from "react-redux";
import {IState} from "../../types/state";
import {FormattedMessage} from "react-intl";

interface IProps {
    children: React.ReactNode
    qty: number
    stockID?: string
    stockValue?: string
}

const InStockProducts: FC<IProps> = ({children, qty, stockID, stockValue}) => {

    const outOfStock = Boolean(+useSelector((state: IState) => state.general.coreConfigs.catalog_products_homepage_out_of_stock_items?.value))
    let value = stockID && stockValue ?
        <div className="search__fm_dr_Message">
            <FormattedMessage
                id={stockID}
                defaultMessage={stockValue}
            />
        </div>
        : <></>


    return (
        <>
            {
                !outOfStock && qty === 0
                    ? value
                    : children
            }
        </>
    )
}

export default InStockProducts