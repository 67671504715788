import React from "react";
import {useSelector} from "react-redux";
import Head from "next/head";
import dynamic from 'next/dynamic'
import {IState} from "../../types/state";
import InStockProducts from "../shared/InStockProducts";

const HotelProductCard = dynamic(() => import("../shared/Hotel_ProductCard"))
const BlockHeaderCustom = dynamic(() => import("../shared/BlockHeaderCustom"))

function BlockProducts(props: any) {
    const {customer, title, layout = "large-first", featuredProduct, products = [], addInClass, dbName} = props;

    const backorders = useSelector((state: IState) => state.general.Backorders);
    const outOfStock = Boolean(+useSelector((state: IState) => state.general.coreConfigs.catalog_products_homepage_out_of_stock_items?.value))

    let large = null;
    let smalls = null;

    const schemaProducts = {
        "@context": `https://schema.org/`,
        "@type": "Product",
        name: "Products",
        offers: [],
        url: `https://${dbName}/`,
    };

    if (featuredProduct) {
        large = (
            <div className="block-products__featured">
                <div className="block-products__featured-item">
                    <HotelProductCard
                        product={featuredProduct}
                        customer={customer}
                        // @ts-ignore
                        rate={props.rate}
                        locale={props.locale}
                    />
                </div>
            </div>
        );
    }
    if (products.length > 0) {
        const productsList = products.map((product: any, index: number) => {
            // @ts-ignore
            return (
                <InStockProducts key={index} qty={product?.product_inventories[0]?.qty}>
                    <div className="product-card-parent">
                        <div className="block-products__list-item">
                            <HotelProductCard
                                product={product}
                                customer={customer}
                                // @ts-ignore
                                rate={props.rate}
                                locale={props.locale}
                            />
                        </div>
                    </div>
                </InStockProducts>
            );
        });
        smalls = <div className="block-products__list">{productsList}</div>;
    }


    function renderSmall() {
        if (products.length > 0) return (<div className="block-products__list">
            {products.map((product: any, index: number) => {
                // schemaProducts.offers.push({
                //   "@type": "Offer",
                //   sku: product?.product_flat[0]?.sku,
                //   image: product?.product_images[0]?.path,
                //   name: product.product_flat[0].name,
                //   description: product?.product_flat[0]?.meta_description || product?.product_flat[0]?.description,
                //   price: product?.product_flat[0]?.special_price || product?.product_flat[0]?.price,
                // });
                // @ts-ignore
                return (
                    <InStockProducts key={index} qty={product?.product_inventories[0]?.qty}>
                        <div key={index} style={{minHeight: 200}} className="product-card-parent">
                            <div className="block-products__list-item">
                                <HotelProductCard
                                    // @ts-ignore
                                    rate={props.rate}
                                    product={product}
                                    customer={customer}
                                    locale={props.locale}
                                />
                            </div>
                        </div>
                    </InStockProducts>
                );
            })}
        </div>);
    }

    return (
        <>
            <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{__html: JSON.stringify(schemaProducts)}}
                />
            </Head>

            <div className={`block-products block-products--layout--${layout}`}>
                <div className="container p-0 home-product-container">

                    {smalls ? <BlockHeaderCustom title={title}/> : ""}

                    <div className={`block-products__body block-${addInClass}-products`}>
                        {layout === "large-first" && large}
                        {renderSmall()}
                        {layout === "large-last" && large}
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlockProducts;
